
.item {
    font-size: 1.2rem;
    list-style-type: none;
    padding: 17px 0px;
    border-bottom: 1px solid #eaeaea;
  }
  
  .checkbox {
    margin-right: 15px;
  }
  
  .item button {
    font-size: 13px;
    background: #f1f3f4;
    border: none;
    cursor: pointer;
    float: right;
    outline: none;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    margin: -10px 0 0 10px;
  }

  .textInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #dfdfdf;
  }