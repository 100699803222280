* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: "Segoe UI", Arial, sans-serif;
    line-height: 1.4;
    color: #444;
    background: #fff;
    height: 100vh;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .inner {
    width: 100%;
    padding: 8rem 10px 4rem;
  }
  
  .form-container {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    border-radius: calc(0.5 * 100px);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.38);
    justify-content: space-evenly;
  }
  
  .input-text {
    font-size: 1rem;
    font-weight: 400;
    width: 85%;
    padding-right: 5px;
    padding-left: 10px;
    border-radius: calc(0.5 * 100px);
  }
  
  .input-text::placeholder {
    color: #000;
  }
  
  .input-submit {
    background: transparent;
    color: #5b5b5b;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 600;
    margin-right: 10px;
  }
  
  .input-text,
  .input-submit {
    height: 45px;
    outline: none;
    border: none;
  }

  .input-submit svg {
    color: darkcyan;
    font-size: 20px;
    margin-top: 2px;
  }

  .delete-btn svg {
    color: darkred;
    font-size: 18px;
    margin-top: 2px;
  }

/* ----------------------------- */

/* sidebar Nav */

.navBar {
  position: relative;
}

.navBar button {
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 10;
  cursor: pointer;
  background: transparent;;
  border: none;
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: darkcyan;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

a {
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  color: #3fffd2;
  text-transform: uppercase;
  font-weight: bold;
}

.menuNav li {
  margin-top: 7rem;
}

/* ----------------------------- */

/* about page */

.about__content {
  padding: 8rem 40px;
  display: flex;
}

.about__list {
  list-style: none;
  flex: 2;
  margin-right: 15px;
}

.about__list a {
  color: #9f9f9f;
  text-transform: capitalize;
  padding: 20px 0;
  font-weight: 600;
}

.about__list a:hover {
  color: #000;
}

.main__content {
  flex: 7;
}

.main__content h1 {
  margin-bottom: 15px;
  line-height: 30px;
  font-size: 30px;
}

.main__content p {
  line-height: 25px;
}
